import buttonFill from './fill/icon-fill-button.svg';
import buttonOutline from './outline/icon-outline-button.svg';

import colourFill from './fill/icon-fill-colour.svg';
import colourOutline from './outline/icon-outline-colour.svg';

import contentFill from './fill/icon-fill-content.svg';
import contentOutline from './outline/icon-outline-content.svg';

import controlsFill from './fill/icon-fill-controls.svg';
import controlsOutline from './outline/icon-outline-controls.svg';

import formsFill from './fill/icon-fill-forms.svg';
import formsOutline from './outline/icon-outline-forms.svg';

import gridFill from './fill/icon-fill-grid.svg';
import gridOutline from './outline/icon-outline-grid.svg';

import identityFill from './fill/icon-fill-identity.svg';
import identityOutline from './outline/icon-outline-identity.svg';

import imageFill from './fill/icon-fill-image.svg';
import imageOutline from './outline/icon-outline-image.svg';

import landmarksFill from './fill/icon-fill-landmarks.svg';
import landmarksOutline from './outline/icon-outline-landmarks.svg';

import layoutFill from './fill/icon-fill-layout.svg';
import layoutOutline from './outline/icon-outline-layout.svg';

import navigationFill from './fill/icon-fill-navigation.svg';
import navigationOutline from './outline/icon-outline-navigation.svg';

import notificationsFill from './fill/icon-fill-notifications.svg';
import notificationsOutline from './outline/icon-outline-notifications.svg';

import overviewFill from './fill/icon-fill-overview.svg';
import overviewOutline from './outline/icon-outline-overview.svg';

import principlesFill from './fill/icon-fill-principles.svg';
import principlesOutline from './outline/icon-outline-principles.svg';

import roadmapFill from './fill/icon-fill-roadmap.svg';
import roadmapOutline from './outline/icon-outline-roadmap.svg';

import rulesFill from './fill/icon-fill-rules.svg';
import rulesOutline from './outline/icon-outline-rules.svg';

import structureFill from './fill/icon-fill-structure.svg';
import structureOutline from './outline/icon-outline-structure.svg';

import typographyFill from './fill/icon-fill-typography.svg';
import typographyOutline from './outline/icon-outline-typography.svg';

import emptyFill from './fill/icon-fill-empty.svg';
import emptyOutline from './outline/icon-outline-empty.svg';

export const SIDEBAR_ICONS = {
  button: [buttonOutline, buttonFill],
  color: [colourOutline, colourFill],
  content: [contentOutline, contentFill],
  controls: [controlsOutline, controlsFill],
  forms: [formsOutline, formsFill],
  grid: [gridOutline, gridFill],
  identity: [identityOutline, identityFill],
  image: [imageOutline, imageFill],
  landmarks: [landmarksOutline, landmarksFill],
  layout: [layoutOutline, layoutFill],
  navigation: [navigationOutline, navigationFill],
  notifications: [notificationsOutline, notificationsFill],
  overview: [overviewOutline, overviewFill],
  principles: [principlesOutline, principlesFill],
  roadmap: [roadmapOutline, roadmapFill],
  rules: [rulesOutline, rulesFill],
  structure: [structureOutline, structureFill],
  typography: [typographyOutline, typographyFill],
  empty: [emptyOutline, emptyFill],
};

export const SIDEBAR_ICON_KEYS = Object.keys(SIDEBAR_ICONS);
