import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Link from '../link/link';

const FooterLinkInner = styled.span``;

const FooterLink = styled(({ children, ...props }) => (
  <Link {...props}>
    <FooterLinkInner>{children}</FooterLinkInner>
  </Link>
))`
  font-family: var(--ds-type-system-sans);
  font-size: var(--ds-type-scale-0);
  font-weight: 700;
  line-height: var(--ds-type-leading-border-link);
  outline: none;
  text-decoration: none;
  ${FooterLinkInner} {
    border-bottom: 0.125rem solid transparent;
    transition: var(--ds-interactions-transition);
  }
  &:hover ${FooterLinkInner} {
    border-bottom-color: currentColor;
  }
  &:focus ${FooterLinkInner} {
    box-shadow: 0 0 0 0.125rem var(--ds-color-hong-kong-55);
    outline: solid transparent;
  }
  &:active ${FooterLinkInner} {
    border-bottom-color: var(--ds-color-hong-kong-55);
    box-shadow: none;
  }
`;

const FooterNavListItem = styled.li``;

const FooterColsAlpha = styled.div`
  border-bottom: var(--ds-border-rule--inverse);
  margin: 0 auto 1rem auto;
  padding: 0 0 0.625rem 0;
  ${FooterLink} {
    display: inline-block;
    margin: 0 0 1.375rem 0;
  }
`;

const FooterColsOmega = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 1.25rem;
`;

const FooterLinkList = styled.ul`
  ${({ theme }) => theme.mediaMinWidth.small`
    ${FooterColsAlpha} & {
      column-count: 2;
      column-gap: var(--ds-grid-gutter);
      column-width: auto;
    }
  `}
  ${({ theme }) => theme.mediaMinWidth.medium`
    ${FooterColsAlpha} & {
      column-count: unset;
      column-gap: unset;
      display: flex;
    }
    li {
        margin-right: var(--ds-grid-gutter);
      }
  `}
`;

const FooterCopyright = styled.p`
  color: var(--ds-color-london-70);
  display: block;
  font-family: var(--ds-type-system-sans);
  font-size: var(--ds-type-scale--1);
  font-weight: 400;
  line-height: var(--ds-type-leading-lower);
  margin: 0 0 1rem 0;
`;

const FooterWrapper = styled.footer`
  background-color: var(--ds-color-london-20);
  color: var(--ds-color-london-100);
  padding: 2rem var(--ds-grid-gap) 0.3125rem;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape in ios */
  ${({ theme }) => theme.mediaMinWidth.medium`
    padding-left: var(--ds-grid-gutter);
    padding-right: var(--ds-grid-gutter);
  `}
`;

export const Footer = ({ links }) => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper itemType='http://schema.org/WPFooter' role='contentinfo'>
      <FooterColsAlpha>
        <FooterLinkList>
          {links.map(({ slug, navigationLabel }) => (
            <FooterNavListItem key={slug}>
              <FooterLink to={slug}>{navigationLabel}</FooterLink>
            </FooterNavListItem>
          ))}
        </FooterLinkList>
      </FooterColsAlpha>
      <FooterColsOmega>
        <FooterCopyright>
          Copyright &copy; The Economist Newspaper Limited {currentYear}. All
          rights reserved.
        </FooterCopyright>
      </FooterColsOmega>
    </FooterWrapper>
  );
};

Footer.defaultProps = {
  links: [],
};

Footer.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Footer;
