import React from 'react';

import { flatten } from './arrays';
import { slugify } from './strings';

/**
 * Accepts any React children and returns an array of text values
 * from all child nodes
 *
 * @param {node[]} reactChildren any React children prop
 * @returns {string[]} nested arrays of text values
 */
export const getRecursiveChildStringNodes = (reactChildren = []) => {
  return React.Children.toArray(reactChildren).map((child) => {
    switch (typeof child) {
      case 'string':
        return child;
      case 'object':
        return child.props
          ? getRecursiveChildStringNodes(child.props.children)
          : null;
      default:
        return null;
    }
  });
};

/**
 * Accepts any React children and returns an array of text values
 * from the first-level children, not nested components
 *
 * @param {node[]} reactChildren any React children prop
 * @returns {string[]} text values
 */
export const getChildrenFirstLevelTextValues = (reactChildren = []) => {
  const nodes = getRecursiveChildStringNodes(reactChildren);
  const flattenedNodes = flatten(nodes, 1);
  return flattenedNodes.filter((node) => typeof node === 'string');
};

/**
 * Accepts any React children and returns a slug of the
 * text values from the child components
 *
 * @param {node[]} reactChildren any React children prop
 * @returns {string} slug string
 */
export const makeSlugFromChildrenTextValues = (reactChildren) => {
  const childrenTextValues = getChildrenFirstLevelTextValues(reactChildren);
  return slugify(childrenTextValues.join(' '));
};
