import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled, { css } from 'styled-components';
import ISvg from 'react-inlinesvg';

import * as propTypes from '../../proptypes';

import Icon from '../icon/icon';
import { Link, ACTIVE_CLASS_NAME } from '../link/link';

import { SIDEBAR_ICONS, SIDEBAR_ICON_KEYS } from './svg';

export const getSidebarIcon = ({ slug }) => {
  if (slug.match(/button/i)) return 'button';
  if (slug.match(/color|colour/i)) return 'color';
  if (slug.match(/content/i)) return 'content';
  if (slug.match(/controls/i)) return 'controls';
  if (slug.match(/feedback|layout/i)) return 'layout';
  if (slug.match(/forms/i)) return 'forms';
  if (slug.match(/grid/i)) return 'grid';
  if (slug.match(/identity/i)) return 'identity';
  if (slug.match(/image|assets/i)) return 'image';
  if (slug.match(/landmarks/i)) return 'landmarks';
  if (slug.match(/navigation/i)) return 'navigation';
  if (slug.match(/notifications/i)) return 'notifications';
  if (slug.match(/overview/i)) return 'overview';
  if (slug.match(/principles/i)) return 'principles';
  if (slug.match(/roadmap/i)) return 'roadmap';
  if (slug.match(/rules/i)) return 'rules';
  if (slug.match(/structure/i)) return 'structure';
  if (slug.match(/typography/i)) return 'typography';
  return undefined;
};

export const SidebarListIcon = styled(
  ({ iconName, iconFill, className, ...props }) => {
    if (!iconName) return null;
    const iconIndex = iconFill ? 1 : 0;
    const iconSrc = SIDEBAR_ICONS[iconName][iconIndex];
    return (
      <span
        className={classnames(`sidebaricon--${iconName}`, className)}
        {...props}
      >
        <ISvg src={iconSrc} />
      </span>
    );
  },
)`
  box-sizing: border-box;
  display: flex;
  height: 2.5rem;
  margin: 0 0.75rem 0 0;
  max-width: 5rem;
  width: 2.5rem;
  > svg {
    /* ensures svgs are full size of the wrapping span */
    display: flex;
    height: 100%;
    width: 100%;
  }
`;

SidebarListIcon.propTypes = {
  iconName: PropTypes.oneOf(SIDEBAR_ICON_KEYS),
  iconFill: PropTypes.bool,
};

SidebarListIcon.defaultProps = {
  iconName: 'empty',
  iconFill: false,
};

export const menuCategoryIsActive = (
  { slug = '__NO_MATCH__' },
  currentUri = '',
) => currentUri.includes(slug);

const renderNestedMenu = (menu = [], depth = 0, currentUri) => {
  if (!menu.length) return null;
  return (
    <SidebarList depth={depth} className={`depth-${depth}`}>
      {menu.map((menuItem) => {
        const categoryIsActive = menuCategoryIsActive(menuItem, currentUri);
        return (
          <SidebarListItem depth={depth} key={menuItem.slug}>
            <SidebarMenuLink
              depth={depth}
              menuItem={menuItem}
              active={categoryIsActive}
            />
            {categoryIsActive && renderNestedMenu(menuItem.menu, depth + 1)}
          </SidebarListItem>
        );
      })}
    </SidebarList>
  );
};

export const SidebarMenuLink = styled(
  ({ menuItem, active, className, depth, ...props }) => {
    if (!menuItem.hasContent && !menuItem.hasChildren) return null;
    const slug = menuItem.redirectTo ? menuItem.redirectTo : menuItem.slug;
    // have to manually 'activate' the current link because sometimes
    // the category links to the first child page, which breaks the
    // 'partiallyActive' check when viewing any other pages
    return (
      <Link
        to={slug}
        className={classnames(className, {
          [ACTIVE_CLASS_NAME]: active,
        })}
        {...props}
      >
        {depth === 0 && (
          <SidebarListIcon
            iconName={getSidebarIcon(menuItem)}
            iconFill={active}
          />
        )}
        {menuItem.navigationLabel}
      </Link>
    );
  },
)`
  align-items: center;
  display: flex;
  outline: solid transparent;
  padding: 0 var(--ds-grid-gap) 0 var(--ds-grid-gap);
  text-decoration: none;
  transition: var(--ds-interactions-transition);
  ${({ depth }) =>
    depth > 0 &&
    css`
      font-size: var(--ds-type-scale-0);
      /* normal gutter + icon width, margin and border */
      padding-left: calc(var(--ds-grid-gap) + 0.75rem + 2.5rem + 1px);
    `}
  &:hover {
    color: var(--ds-color-chicago-30);
  }
  &:focus {
    box-shadow: 0 0 0 0.125rem var(--ds-color-hong-kong-55);
  }
  &:active {
    color: var(--ds-color-london-5);
  }
  &.is-active {
    font-weight: 700;
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMinWidth.medium`
    padding-left: var(--ds-grid-gutter);
    padding-right: var(--ds-grid-gutter);
    ${({ depth }) =>
      depth > 0 &&
      css`
        /* normal gutter + icon width, margin and border */
        padding-left: calc(var(--ds-grid-gutter) + 0.75rem + 2.5rem + 1px);
      `}
  `}
`;

SidebarMenuLink.propTypes = {
  menuItem: propTypes.navMenu.isRequired,
  active: PropTypes.bool,
};

SidebarMenuLink.defaultProps = {
  active: false,
};

export const SidebarList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
  ${({ depth }) =>
    depth === 0
      ? css`
          padding-bottom: 1.25rem;
          padding-top: 1.25rem;
        `
      : css`
          padding-bottom: 0.75rem;
          padding-top: 0.75rem;
        `}
`;

export const SidebarListItem = styled.li`
  ${({ depth }) =>
    depth === 0
      ? css`
          margin: 0 0 1.25rem;
        `
      : css`
          margin: 0 0 0.75rem;
        `}
  &:last-child {
    margin-bottom: 0;
  }
`;

const SidebarHeaderTitle = styled.h5`
  font-weight: 700;
  margin: 0;
`;

const SidebarHeader = styled.header``;

const SidebarMenu = styled.div`
  &:not(:last-child) {
    border-bottom: var(--ds-border-rule);
  }
`;

const SidebarContent = styled.div`
  ${({ theme }) => theme.mediaMaxWidth.large`
    .is-open & {
      border-bottom: var(--ds-border-rule);
    }
  `}
`;

const SidebarHeaderButtonIcon = styled((props) => (
  <Icon {...props} icon='chevronDown' />
))`
  transform: rotateX(0);
  transition: var(--ds-interactions-transition);
  .is-open & {
    transform: rotateX(180deg);
  }
`;

const SidebarHeaderButton = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border-bottom: var(--ds-border-rule);
  border-left: none;
  border-right: none;
  border-top: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: inherit;
  justify-content: space-between;
  padding: 1rem var(--ds-grid-gap);
  text-align: left;
  transition: var(--ds-interactions-transition);
  width: 100%;
  &:hover {
    color: var(--ds-color-chicago-30);
  }
  &:focus {
    box-shadow: 0 0 0 0.125rem var(--ds-color-hong-kong-55);
    outline: solid transparent;
  }
  &:active {
    color: var(--ds-color-london-5);
  }
  /* needed to ensure specificity */
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMinWidth.medium`
    padding-left: var(--ds-grid-gutter);
    padding-right: var(--ds-grid-gutter);
  `}
`;

export const Sidebar = styled(({ className, section, currentUri }) => {
  if (!section) return null;
  if (section.sidebarMenus.every((menu) => menu.length === 0)) return null;

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebarState = () => setSidebarOpen(!sidebarOpen);

  const classNames = classnames(className, {
    'is-open': sidebarOpen,
  });

  return (
    <div className={classNames}>
      <SidebarHeader>
        <SidebarHeaderButton onClick={toggleSidebarState}>
          <SidebarHeaderTitle>{section.navigationLabel}</SidebarHeaderTitle>
          <SidebarHeaderButtonIcon />
        </SidebarHeaderButton>
      </SidebarHeader>
      <SidebarContent>
        {section.sidebarMenus.map((menus, i) => {
          if (!menus.length) return null;
          const key = `sidebar-menu-${i}`;
          return (
            <SidebarMenu key={key}>
              {renderNestedMenu(menus, 0, currentUri)}
            </SidebarMenu>
          );
        })}
      </SidebarContent>
    </div>
  );
})`
  background-color: var(--dsw-color-london-98);
  color: var(--ds-color-london-5);
  font-family: var(--ds-type-system-sans);
  font-size: var(--ds-type-scale-1);
  font-weight: 400;
  line-height: var(--ds-type-leading-lower);
  :not(.is-open) > ${SidebarContent} {
    /* just here for now; preferred not to display:none */
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  /* needed to ensure specificity */
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMinWidth.large`
    border-right: var(--ds-border-rule);
    ${SidebarHeader} {
      /* hidden on larger desktops; not required for a11y  */
      display: none;
    }
    :not(.is-open) > ${SidebarContent} {
      max-height: none;
      overflow: visible;
      visibility: visible;
    }
  `};
`;

Sidebar.defaultProps = {
  section: null,
  currentUri: null,
  className: undefined,
};

Sidebar.propTypes = {
  section: propTypes.navMenu,
  currentUri: PropTypes.string,
  className: PropTypes.string,
};
