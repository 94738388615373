import PropTypes from 'prop-types';

/**
 * componentMetadata PropTypes
 *
 */
export const componentMetadata = PropTypes.shape({
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  props: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.shape({
        name: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        value: PropTypes.any, // can be any valid prop-type
      }),
      defaultValue: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
  ),
});

/**
 * MDXAST PropTypes
 *
 */
export const mdxAstNode = PropTypes.shape({
  type: PropTypes.string,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      // itself
    }),
  ),
  depth: PropTypes.number, // ony for headings
  position: PropTypes.shape({
    start: PropTypes.shape({
      line: PropTypes.number,
      column: PropTypes.number,
      offset: PropTypes.number,
    }),
    end: PropTypes.shape({
      line: PropTypes.number,
      column: PropTypes.number,
      offset: PropTypes.number,
    }),
  }),
});

/**
 * MDX PropTypes
 *
 */
export const mdx = PropTypes.shape({
  id: PropTypes.string.isRequired,
  body: PropTypes.string,
  mdxAST: mdxAstNode,
  fields: PropTypes.shape({
    slug: PropTypes.string,
    paths: PropTypes.arrayOf(PropTypes.string),
    parent: PropTypes.string,
  }),
  frontmatter: PropTypes.shape({
    navigationLabel: PropTypes.string,
    componentName: PropTypes.string,
    navMenu: PropTypes.arrayOf(PropTypes.string),
    order: PropTypes.number,
    sidebarLocation: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
});

/**
 * Nav Menu PropTypes
 *
 */
export const navMenuShape = {
  hasContent: PropTypes.bool.isRequired,
  hasChildren: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
  navigationLabel: PropTypes.string.isRequired,
};
export const navMenu = PropTypes.shape({
  ...navMenuShape,
  menu: PropTypes.arrayOf(PropTypes.shape(navMenuShape)),
});

/**
 * Table of Contents menu PropTypes
 *
 */
const tableOfContentsShape = {
  depth: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
export const tableOfContents = {
  ...tableOfContentsShape,
  menu: PropTypes.arrayOf(PropTypes.shape(tableOfContentsShape)),
};
