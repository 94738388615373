import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { redirectTo } from '../../utils/gatsby';

import { DESIGN_SYSTEM_STYLES } from '../../constants';
import { ThemeProvider } from '../../theme';
import { GlobalStyles } from '../../styles';
import { Sidebar } from '../sidebar/sidebar';
import Masthead from '../masthead/masthead';
import Footer from '../footer/footer';
import Seo from '../seo/seo';
import WithGatsbyStaticNavigation from '../with-gatsby-static-nav/with-gatsby-static-nav';

const LayoutContent = styled.div`
  display: grid;
  padding: 0 var(--ds-grid-gap);
  ${({ theme }) => theme.mediaMinWidth.medium`
    padding-left: var(--ds-grid-gutter);
    padding-right: var(--ds-grid-gutter);
  `}
  ${({ theme }) => theme.mediaMinWidth.large`
    flex-grow: 1;
    grid-auto-flow: row;
    grid-column-gap: var(--ds-grid-gutter);
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 100%;
  `}
`;

const LayoutMain = styled.main`
  grid-area: auto / 1 / auto / -1;
  &:only-child {
    grid-column-start: 1;
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMinWidth.large`
    grid-column-start: 4;
  `}
  ${({ theme }) => theme.mediaMinWidth.max`
    grid-column: 3 / 11;
  `}
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${Sidebar} {
    grid-area: auto / 1 / auto / -1;
    margin: 0 0 0 calc(var(--ds-grid-gap) * -1);
    width: calc(100% + (var(--ds-grid-gap) * 2));
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMinWidth.medium`
    ${Sidebar} {
      margin-left: calc(var(--ds-grid-gutter) * -1);
      width: calc(100% + (var(--ds-grid-gutter) * 2));
    }
  `}
  ${({ theme }) => theme.mediaMinWidth.large`
    ${Sidebar} {
      grid-column: span 3;
      width: auto;
    }
  `}
  ${({ theme }) => theme.mediaMinWidth.max`
    ${Sidebar} {
      grid-column: span 2;
    }
  `}
`;

const LayoutHeader = styled.div`
  border-bottom: var(--ds-border-rule);
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.layoutHeader};
`;

const makeSeoTitle = (section, category, route) => {
  const titles = [];
  if (route) titles.push(route.navigationLabel);
  if (category) titles.push(category.navigationLabel);
  if (section) titles.push(section.navigationLabel);
  // removes duplicates eg: if the category is the same as the current route
  return [...new Set(titles)];
};

const Layout = ({ children, uri }) => (
  <ThemeProvider>
    <Seo>
      <link type='text/css' rel='stylesheet' href={DESIGN_SYSTEM_STYLES} />
    </Seo>
    <GlobalStyles />
    <WithGatsbyStaticNavigation currentUri={uri}>
      {({
        primaryMenu,
        footerLinks,
        currentSection,
        currentCategory,
        currentRoute,
      }) => {
        if (currentRoute && currentRoute.redirectTo) {
          redirectTo(currentRoute.redirectTo);
          return null;
        }
        const title = makeSeoTitle(
          currentSection,
          currentCategory,
          currentRoute,
        );
        return (
          <>
            <Seo title={title} />
            <LayoutWrapper>
              <LayoutHeader>
                <Masthead menu={primaryMenu} />
              </LayoutHeader>
              <LayoutContent>
                <Sidebar section={currentSection} currentUri={uri} />
                <LayoutMain>{children({ currentCategory })}</LayoutMain>
              </LayoutContent>
            </LayoutWrapper>
            <Footer links={footerLinks} />
          </>
        );
      }}
    </WithGatsbyStaticNavigation>
  </ThemeProvider>
);

Layout.defaultProps = {};

Layout.propTypes = {
  children: PropTypes.func.isRequired,
  uri: PropTypes.string.isRequired,
};

export default Layout;
