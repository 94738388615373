import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import ISvg from 'react-inlinesvg';

import { ICONS, ICON_NAMES } from './svgs';

export const Svg = styled(ISvg)`
  /* ensures svgs are full size of the wrapping span */
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Icon = styled(({ icon, className, ...props }) => (
  <span className={classnames(`icon--${icon}`, className)} {...props}>
    <Svg src={ICONS[icon]} />
  </span>
))`
  display: flex;
  max-width: 5rem;
`;

Icon.propTypes = {
  icon: PropTypes.oneOf(ICON_NAMES).isRequired,
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: undefined,
};

export default Icon;
