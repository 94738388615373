/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled, { css } from 'styled-components';

import { makeSlugFromChildrenTextValues } from '../../utils/components';
import Link from '../link/link';
import Icon from '../icon/icon';
import { scrollIntoView } from '../../utils/dom';
import { makeUrlHash } from '../../utils/strings';

const handleHeaderClick = (id) => (evt) => {
  evt.preventDefault();
  scrollIntoView(document.getElementById(id));
};

// helper to easily generate header elements with anchor links
const HeaderWithLink = ({ children, tag, id, ...props }) => {
  const idSlug = id || makeSlugFromChildrenTextValues(children);
  const headerUrl = makeUrlHash(idSlug);
  const HTag = tag;
  return (
    <HTag id={idSlug} {...props}>
      <MdxHeaderLink to={headerUrl} onClick={handleHeaderClick(idSlug)} />
      {children}
    </HTag>
  );
};

HeaderWithLink.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.string,
  id: PropTypes.string,
};
HeaderWithLink.defaultProps = {
  children: null,
  tag: 'h1',
  id: null,
};

// -----------------------------------------------------------------------------
// Base elements
// -----------------------------------------------------------------------------
export const MdxHeaderLink = styled((props) => (
  <Link {...props} tabIndex='-1' aria-hidden>
    <Icon icon='link' />
  </Link>
))`
  ${Icon} {
    width: 1.125rem;
  }
`;

const commonHeaderStyles = css`
  color: var(--ds-color-london-5);
  font-family: var(--ds-type-system-serif);
  position: relative;
  ${MdxHeaderLink} {
    align-items: center;
    display: flex;
    /* exactly one line high */
    height: calc(1em * var(--ds-type-leading-upper));
    justify-content: center;
    left: calc(var(--ds-grid-gutter) * -1);
    opacity: 0;
    position: absolute;
    top: 0;
    transition: var(--ds-interactions-transition) opacity;
    visibility: hidden;
    width: var(--ds-grid-gutter);
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMinWidth.medium`
    ${MdxHeaderLink} {
      visibility: visible;
    }
    &:hover ${MdxHeaderLink} {
      opacity: 1;
    }
  `}
`;

export const MdxH1 = styled(HeaderWithLink).attrs({ tag: 'h1' })`
  ${commonHeaderStyles}
  font-size: var(--ds-type-scale-7);
  font-weight: 700;
  line-height: var(--ds-type-leading-upper);
  margin: 0 0 0.5rem;
  max-width: 46rem;
  ${({ theme }) => theme.mediaMinWidth.small`
    font-size: var(--ds-type-scale-9);
  `}
  ${({ theme }) => theme.mediaMinWidth.medium`
    font-size: var(--ds-type-scale-10);
  `}
`;

export const MdxH2 = styled(HeaderWithLink).attrs({ tag: 'h2' })`
  ${commonHeaderStyles}
  font-size: var(--ds-type-scale-5);
  font-weight: 700;
  line-height: var(--ds-type-leading-upper);
  margin: 0 0 1rem;
  max-width: 42.5rem;
  ${({ theme }) => theme.mediaMinWidth.small`
    font-size: var(--ds-type-scale-7);
  `}
`;

export const MdxH3 = styled(HeaderWithLink).attrs({ tag: 'h3' })`
  ${commonHeaderStyles}
  font-size: var(--ds-type-scale-4);
  font-weight: 500;
  line-height: var(--ds-type-leading-upper);
  margin: 0 0 0.75rem;
  max-width: 42.5rem;
  ${({ theme }) => theme.mediaMinWidth.small`
    font-size: var(--ds-type-scale-5);
  `}
`;

export const MdxHr = styled(({ emphasised, className, ...props }) => (
  <hr
    className={classnames(className, {
      'MdxHr--emphasised': emphasised,
    })}
    {...props}
  />
))`
  background: none;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: var(--ds-border-rule);
  height: 0;
  margin: 1rem 0;
  width: 100%;
  ${({ emphasised }) =>
    emphasised &&
    css`
      border-top: var(--ds-border-rule--emphasised);
      ${'' /* + h2[hidden] + h3 {
        margin-top: 2rem;
      } */}
    `}
`;

export const MdxP = styled.p`
  color: var(--ds-color-london-5);
  font-family: var(--ds-type-system-serif);
  font-size: var(--ds-type-scale-1);
  font-weight: 400;
  line-height: var(--ds-type-leading-lower);
  margin: 0 0 0.875rem 0; /* 14px, half of lower leading */
  max-width: 42.5rem;
  + ${MdxHr} {
    margin-top: 1.125rem;
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMinWidth.small`
    font-size: var(--ds-type-scale-2);
  `}
`;

export const MdxCategory = styled.p`
  color: var(--ds-color-economist-red);
  font-family: var(--ds-type-system-sans);
  font-size: var(--ds-type-scale--1);
  font-weight: 400;
  line-height: var(--ds-type-leading-lower);
  margin: 0 0 0.125rem;
`;

// -----------------------------------------------------------------------------
// Custom MDX components
// -----------------------------------------------------------------------------

export const MdxHeader = styled(MdxH1)`
  /* MdxHeader styled the same as H1 */
`;

export const MdxHeaderTertiary = styled(MdxH3)`
  /* MdxHeaderTertiary styled the same as H3 */
`;

export const MdxHeaderTertiaryVariant = styled(MdxHeaderTertiary)`
  font-size: var(--ds-type-scale-2);
  font-style: italic;
  font-weight: 400;
  line-height: var(--ds-type-leading-lower);
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMinWidth.small`
    font-size: var(--ds-type-scale-2);
  `}
`;

export const MdxHeaderSecondary = styled(MdxH2)`
  /* MdxHeaderSecondary styled the same as H2 */
  + ${MdxHr} {
    margin-top: 1rem;
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMaxWidth.large`
    + ${MdxHeaderTertiaryVariant} {
      margin-top: 1rem;
    }
  `}
`;

export const MdxLede = styled(MdxP)`
  font-size: var(--ds-type-scale-2);
  line-height: var(--ds-type-leading-lower);
  margin-bottom: 3rem;
  max-width: 46rem;
  /* stylelint-disable-next-line plugin/stylelint-bem-namics */
  + ${MdxHr} {
    margin-top: 0rem;
    /* stylelint-disable-next-line */
    + ${MdxHeaderSecondary}[hidden] + ${MdxHeaderTertiaryVariant} {
      margin-top: 0.5rem;
    }
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMinWidth.small`
    font-size: var(--ds-type-scale-3);
    line-height: var(--ds-type-leading-upper);
  `}
  ${({ theme }) => theme.mediaMaxWidth.xlarge`
    margin-bottom: 2rem;
    + ${MdxHr} {
      margin-top: 1rem;
    }
  `}
`;

export const MdxImage = styled.img``;

export const MdxFigure = styled(({ src, alt, ...props }) => (
  <figure {...props}>
    <MdxImage src={src} alt={alt} />
  </figure>
))`
  ${MdxImage} {
    display: block;
    max-width: 100%;
  }
`;

export const MdxFigurePair = styled.div`
  ${({ theme }) => theme.mediaMaxWidth.medium`
    ${MdxImage} {
      margin: 0 0 var(--ds-grid-gutter);
    }
  `}
  ${({ theme }) => theme.mediaMinWidth.medium`
    display: grid;
    grid-gap: var(--ds-grid-gutter);
    grid-template-columns: repeat(2, fit-content(50%));
  `}
`;

export const MdxArticle = styled.article`
  b,
  strong {
    font-weight: 700;
  }
  em,
  i {
    font-style: italic;
  }
  figure {
    margin: 1.3125rem 0 2.1875rem;
    figcaption {
      font-family: var(--ds-type-system-sans);
      font-size: var(--ds-type-scale-0);
      font-weight: 500;
    }
  }
  iframe {
    border: 0;
    max-width: 100%;
  }
  object {
    max-width: 100%;
  }
  sub {
    font-size: smaller;
    vertical-align: sub;
  }
  sup {
    font-size: smaller;
    vertical-align: super;
  }
  u {
    text-decoration: underline;
  }
  > ol {
    margin: 0 0 0.875rem 0; /* 14px, half of lower leading */
    overflow: auto;
    li {
      color: var(--ds-color-london-5);
      font-family: var(--ds-type-system-serif);
      font-size: var(--ds-type-scale-1);
      font-weight: 400;
      line-height: var(--ds-type-leading-lower);
      list-style-type: decimal;
      margin: 0 0 0.4375rem var(--ds-grid-gutter); /* 7px, half of lower leading */
      ${({ theme }) => theme.mediaMinWidth.small`
        font-size: var(--ds-type-scale-2);
      `}
      ol {
        margin-top: 0.4375rem;
      }
      ul {
        margin-top: 0.4375rem;
      }
    }
  }
  /* stylelint-disable-next-line no-descending-specificity */
  > ul {
    margin: 0 0 0.875rem 0; /* 14px, half of lower leading */
    overflow: auto;
    li {
      color: var(--ds-color-london-5);
      font-family: var(--ds-type-system-serif);
      font-size: var(--ds-type-scale-1);
      font-weight: 400;
      line-height: var(--ds-type-leading-lower);
      list-style-type: disc;
      margin: 0 0 0.4375rem var(--ds-grid-gutter); /* 7px, half of lower leading */
      ${({ theme }) => theme.mediaMinWidth.small`
        font-size: var(--ds-type-scale-2);
      `}
      ol {
        margin-top: 0.4375rem;
        li {
          list-style-type: decimal;
        }
      }
      ul {
        margin-top: 0.4375rem;
        li {
          list-style-type: circle;
        }
      }
    }
  }
`;
