import PropTypes from 'prop-types';
import { useGatsbyStaticNavigation } from '../../utils/gatsby';

export const WithGatsbyStaticNavigation = ({ children, currentUri }) => {
  const gatsbyNavigation = useGatsbyStaticNavigation(currentUri);
  return children(gatsbyNavigation);
};

WithGatsbyStaticNavigation.propTypes = {
  children: PropTypes.func.isRequired,
  currentUri: PropTypes.string,
};

WithGatsbyStaticNavigation.defaultProps = {
  currentUri: null,
};

export default WithGatsbyStaticNavigation;
