import { useStaticQuery, graphql, navigate } from 'gatsby';

import { slugToLabel } from './strings';

export const SIDEBAR_LOCATION_DEFAULT = 2;
export const ORDER_DEFAULT = 9999;

/**
 * Helper to control manual redirects. Replaces window state
 *
 * @param {string} path path string to redirect to
 * @returns {void}
 */
export const redirectTo = (path = '') =>
  navigate(path, {
    replace: true,
  });

/**
 * Helper to control manual navigation
 *
 * @param {string} path path string to navigate to
 * @returns {void}
 */
export const navigateTo = (path = '') => navigate(path);

/**
 * Sorts an array of modified nodes first by
 * order and then alphabetically
 *
 * @param {object} nodeA first node
 * @param {string} nodeA.slug node URI
 * @param {number} nodeA.ordeer node order
 * @param {object} nodeB second node
 * @param {string} nodeB.slug node URI
 * @param {number} nodeB.order node order
 * @returns {number} the sorting index
 */
export const sortNodes = (nodeA, nodeB) => {
  const slugA = nodeA.slug.toUpperCase();
  const slugB = nodeB.slug.toUpperCase();

  const orderA = nodeA.order || ORDER_DEFAULT;
  const orderB = nodeB.order || ORDER_DEFAULT;

  if (orderA < orderB) return -1;
  if (orderA > orderB) return 1;

  if (slugA > slugB) return 1;
  if (slugA < slugB) return -1;

  return 0;
};

/**
 * Takes an array of any route menu items
 *
 * @param {object[]} menu array of menu items
 * @param {number} menu.sidebarLocation sidebar menu index
 * @returns {array[]} sidebar breakout menus
 */
export const makeSidebarBreakoutMenus = (menu) =>
  menu.reduce(
    (menus, menuItem) => {
      const sidebarLocation =
        Number(menuItem.sidebarLocation) || SIDEBAR_LOCATION_DEFAULT;
      const location = sidebarLocation - 1;
      // clamp the sidebar location indexes to match our nested arrays
      const menuIndex = Math.max(0, Math.min(2, location));
      menus[menuIndex].push(menuItem);
      return menus;
    },
    // nested arrays of sidebar menus
    // eslint-disable-next-line
    [[/* 1 */], [/* 2 */], [/* 3 */]],
  );

/**
 * Returns true if a  Gatsby node has content. 'Content' is anything other than
 * frontmatter, which gatsby-plugin-mdx names as 'export'
 *
 * @param {object} node gatsby node
 * @param {object} node.mdxAST mdx nodes
 * @param {stirng} node.mdxAST.type mdx type (root)
 * @param {object[]} node.mdxAST.children child nodes
 * @param {string} node.mdxAST.children[].type child node type
 * @returns {boolean} bool if the node has mdx content other that frontmatter
 * or contains React components which don't render content
 */
export const nodeHasContent = ({ mdxAST }) =>
  mdxAST.children.some(({ type, value, children }) => {
    if (type === 'export') return false;
    if (children) return !!children.length;
    if (typeof value === 'string') return !value.match(/^(<Seo)/);
    return false;
  });

/**
 * Accepts two lists of Gatsby node edges and returns a list of nav
 * routes, sorting by slug
 *
 * @param {object[]} mdxEdges An array of mdx Gatsby node-edges
 * @param {object[]} markdownEdges An array of markdown Gatsby node-edges
 * @returns {object[]} Array of route properties
 */
export const makeRoutesList = (mdxEdges = [], markdownEdges = []) => {
  const markdownRoutes = markdownEdges.map(({ node }) => ({
    ...node.fields,
    order: ORDER_DEFAULT,
    navigationLabel: slugToLabel(node.fields.slug),
    navMenu: [],
    hasContent: true,
    sidebarLocation: undefined,
  }));
  const mdxRoutes = mdxEdges.map(({ node }) => ({
    ...node.fields,
    // pages without an order will always be appended
    order: node.frontmatter.order || ORDER_DEFAULT,
    navigationLabel: node.frontmatter.navigationLabel,
    navMenu: node.frontmatter.navMenu || [],
    hasContent: nodeHasContent(node),
    sidebarLocation: Number.isInteger(node.frontmatter.sidebarLocation)
      ? node.frontmatter.sidebarLocation
      : SIDEBAR_LOCATION_DEFAULT,
  }));
  return [...markdownRoutes, ...mdxRoutes].sort(sortNodes);
};

/**
 * Accepts a list of site-wide routes and an optional parent param
 * to look in, returns a nested list of routes.
 *
 * @param {object[]} routes An array of route properties from makeRoutesList
 * @param {string} parent optional parent to filter by
 * @returns {object[]} a nested list of all routes
 */
export const makeRecursiveMenu = (routes, parent) => {
  const result = [];
  routes
    .filter((route) => {
      const routeParent = route.paths[route.paths.length - 2] || null;
      return routeParent === parent;
    })
    .forEach((route) => {
      const pathsCount = route.paths.length;
      const isSection = pathsCount === 1;
      const menu = makeRecursiveMenu(routes, route.paths[pathsCount - 1]);
      const hasChildren = !!menu.length;
      const sidebarMenus = isSection ? makeSidebarBreakoutMenus(menu) : [];

      // if the page has no content but has child pages then
      // the browser should redirect to the first child page
      let redirectToChild = null;
      if (!route.hasContent && hasChildren) {
        const [firstChildRoute] = menu;
        redirectToChild = firstChildRoute.slug;
      }

      // assign the nested menu and redirection to the current route
      route.menu = menu; /* eslint-disable-line no-param-reassign */
      route.redirectTo = redirectToChild; /* eslint-disable-line no-param-reassign */

      result.push({
        ...route,
        hasChildren,
        sidebarMenus,
      });
      return result;
    });

  return result;
};

/**
 * Find a route by slug/uri
 *
 * @param {string} [routeSlug] The route slug/uri to filter by
 * @param {object[]} [routes] array of routes
 * @param {string} routes[].slug route slug/uri
 * @returns {object} route
 */
export const findRouteBySlug = (routeSlug = '__NO_MATCH__', routes = []) =>
  routes.find(({ slug }) => slug.replace(/\/$/, '') === routeSlug);

/**
 * Looks for the route object by path depth
 *
 * @param {object} route the current route object
 * @param {object[]} routes routes to filter through
 * @param {number} depth depth to search the paths
 * @returns {object} the found route
 */
export const findRouteByDepth = (route, routes, depth = 0) => {
  if (!route) return undefined;
  return routes.find(({ paths }) => paths[depth] === route.paths[depth]);
};

/**
 * Returns a list of routes for the current section,
 * no matter the depth of the page
 *
 * @param {object} route the current route object
 * @param {object[]} routes routes to filter through
 * @returns {object} route for the current section
 */
export const findSectionByRoute = (route, routes = []) =>
  findRouteByDepth(route, routes, 0);

/**
 * Returns a list of routes for the current category,
 * no matter the depth of the page
 *
 * @param {object} route the current route object
 * @param {object[]} routes routes to filter through
 * @returns {object[]} routes for the current category
 */
export const findCategoryByRoute = (route, routes = []) =>
  findRouteByDepth(route, routes, 1);

/**
 * Takes a set of Gatsby pages and returns a helpful navigation object
 *
 * @param {object} mdxPages Gatsby pages
 * @param {object} pages.edges Gatsby page nodes/edges
 * @param {object} markdownPages Gatsby page nodes/edges
 * @param {object} markdownPages.edges Gatsby page nodes/edges
 * @param {*} currentUri the current URI
 * @returns {object} useful nav objects/routes
 */
export const makeNavigationFromPages = (
  mdxPages,
  markdownPages,
  currentUri = '__NO_MATCH__',
) => {
  const mdxEdges = mdxPages ? mdxPages.edges : [];
  const markdownEdges = markdownPages ? markdownPages.edges : [];
  const routes = makeRoutesList(mdxEdges, markdownEdges);
  const menu = makeRecursiveMenu(routes, null);
  const currentRoute = findRouteBySlug(currentUri, routes);
  const currentSection = findSectionByRoute(currentRoute, menu);
  const currentCategory = currentSection
    ? findCategoryByRoute(currentRoute, currentSection.menu)
    : undefined;
  const primaryMenu = menu.filter(({ navMenu }) => navMenu.includes('primary'));
  const footerLinks = menu.filter(({ navMenu }) => navMenu.includes('footer'));

  return {
    menu,
    routes,
    currentUri,
    primaryMenu,
    currentRoute,
    currentSection,
    currentCategory,
    footerLinks,
  };
};

/**
 * Hook to query all pages in the site and return
 * an object of routes and a nested menu.
 *
 * @param {string} [currentUri] The slug/uri of the current route
 * @returns {object} useful nav objects/routes
 */
export const useGatsbyStaticNavigation = (currentUri = '__NO_MATCH__') => {
  const { mdxPages, markdownPages } = useStaticQuery(graphql`
    query RoutesQuery {
      mdxPages: allMdx(
        filter: {
          fields: { slug: { ne: null } }
          frontmatter: { navigationLabel: { ne: null } }
        }
      ) {
        edges {
          node {
            id
            mdxAST
            fields {
              slug
              paths
              parent
            }
            frontmatter {
              order
              navMenu
              componentName
              navigationLabel
              sidebarLocation
            }
          }
        }
      }
      markdownPages: allMarkdownRemark(
        filter: { fields: { slug: { ne: null } } }
      ) {
        edges {
          node {
            id
            html
            rawMarkdownBody
            fields {
              slug
              paths
            }
          }
        }
      }
    }
  `);

  return makeNavigationFromPages(mdxPages, markdownPages, currentUri);
};
