/* eslint-disable import/prefer-default-export */
/**
 * Taken from MDN, node only supports [].flat() in node v11
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat#Alternatives
 *
 * @param {array} arr array to flatten
 * @param {number} depth required deptch
 * @returns {array} flattened arrray
 */
export const flatten = (arr = [], depth = 1) =>
  depth > 0
    ? arr.reduce(
        (acc, val) =>
          acc.concat(Array.isArray(val) ? flatten(val, depth - 1) : val),
        [],
      )
    : arr.slice();
