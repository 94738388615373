import { createGlobalStyle } from 'styled-components';
import { makeCssVars } from './theme';

export const GlobalStyles = createGlobalStyle`
  /* we shouldn't need much here since importing the
  Design System should take care of most of our base styles */
  ${makeCssVars}
  html, body, #___gatsby, #gatsby-focus-wrapper {
    min-height: 100vh;
  }
  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  body {
    /* need extra specificity to override DS display:none  */
    /* https://a11yproject.com/posts/how-to-hide-content  */
    [hidden] {
      clip: rect(1px, 1px, 1px, 1px);
      display: initial;
      height: 1px;
      /* offset the 1px height because deep links
      don't work with position: abs */
      margin-bottom: -1px;
      overflow: hidden;
      white-space: nowrap;
      width: 1px;
    }
    strong {
      font-weight: 700;
    }
    code {
      font-family: monospace;
    }
  }

`;

export default GlobalStyles;
