import placeholder from './placeholder.svg';
import chevronDown from './chevron-down.svg';
import link from './link.svg';
import launch from './launch.svg';

export const ICONS = {
  placeholder,
  chevronDown,
  link,
  launch,
};

export const ICON_NAMES = Object.keys(ICONS);
